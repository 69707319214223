import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

const IndexPage = () => (
    <Layout>
        <h1>I am Gokul</h1>
        <p>You came to my website. This means a lot. My website is still under progress like most of the things I do.</p>

        <p>I am an Egnineer Managerat <a href="https://twitter.com/grofers">@grofers</a>. I take care of CRM and CMS teams.</p>

        <p>When I am not doing work related to Grofers, am either playing with my son Abhirath or reading up something. </p>

        <p>I have created a curation sub section on the site <Link to="/curious-about">Curious About</Link></p>
    </Layout>
);

export default IndexPage;